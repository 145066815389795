import { useLayout } from '~/src/hooks/layout/useLayout'
import useDialog from '~/src/hooks/useDialog'
import { useEffect } from 'react'
import ForceUpdateDialog from '~/src/components/force-update/dialog'

const Update = () => {
  const { present } = useDialog()

  useLayout('update')

  useEffect(() => {
    present(ForceUpdateDialog, 'force-update-dialog')
  }, [])

  return null
}

export default Update
