import { AppService } from '@eo-storefronts/eo-core'
import useAppService from '~/src/hooks/services/useAppService'

interface ReturnsType {
  href: string | undefined,
}

const useGetAppStoreUrl = (): ReturnsType => {
  const appService: AppService = useAppService()

  return {
    href: appService.getAppHref()
  }
}

export default useGetAppStoreUrl
