import { Player } from '@lottiefiles/react-lottie-player'
import Title from '~/src/components/mui-wrappers/Title'
import { Box, Typography } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'

const ForceUpdateContent = () => {
  const muiTheme = useCustomMuiTheme()
  const { t } = useTranslations()

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mb: 4
    }}>
      <Player
        src='/images/lotties/update-app.json'
        autoplay
        loop
        style={{ width: '100%', height: '100%' }}
      />

      <Title
        variant='h4'
        sx={{
          mt: 4,
          mb: 2,
          textAlign: 'center',
          px: 4,
          [muiTheme.breakpoints.down('sm')]: {
            px: 2
          }
        }}>
        {t('update_app.title')}
      </Title>
      <Typography
        variant='h6'
        sx={{
          textAlign: 'center',
          px: 4,
          [muiTheme.breakpoints.down('sm')]: {
            px: 2
          }
        }}
      >
        {t('update_app.sub_title')}
      </Typography>
    </Box>
  )
}

export default ForceUpdateContent
