import { Container } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import ForceUpdateAction from '~/src/components/force-update/ForceUpdateAction'
import ForceUpdateContent from '~/src/components/force-update/ForceUpdateContent'

const ForceUpdateContainer = () => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Container
      maxWidth='sm'
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [muiTheme.breakpoints.up('md')]: {
          px: 4,
          my: 14,
          height: `calc(100% - ${muiTheme.spacing(14)} * 2)`
        }
      }}>
      <ForceUpdateContent />

      <ForceUpdateAction />
    </Container>
  )
}

export default ForceUpdateContainer
