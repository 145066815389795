import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import ForceUpdateContainer from '~/src/components/force-update'

const ForceUpdateDialog = () => (
  <ResponsiveDialog
    openByDefault
    showCloseButton={false}
    responsive={false}
    fullScreen
    persistent
    sx={{ zIndex: 99999 }}>
    <ForceUpdateContainer />
  </ResponsiveDialog>
)

export default ForceUpdateDialog
