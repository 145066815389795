import { useTranslations } from '~/src/hooks/useTranslations'
import ButtonLink from '~/src/components/mui-wrappers/buttons/ButtonLink'
import useGetAppStoreUrl from '~/src/hooks/app/useGetAppStoreUrl'

const ForceUpdateAction = () => {
  const { t } = useTranslations()
  const { href } = useGetAppStoreUrl()

  const _handleOnClick = () => {
    window.open(href)
  }

  if (!href) {
    return null
  }

  return (
    <ButtonLink
      displayIcon={false}
      fullWidth
      variant='contained'
      color='primary'
      onClick={_handleOnClick}
      linkProps={{
        href,
        className: 'eo-w-full',
        resolve: false
      }}
      sx={{
        px: 4,
        py: 2,
        boxShadow: 0
      }}>
      {t('update_app.call_to_action')}
    </ButtonLink>
  )
}

export default ForceUpdateAction
