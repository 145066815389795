import { AppService, Firm, IndexDBService } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { AppInjector } from '~/src/utils/DependenciesInjectionUtils'

const useAppService = (): AppService => {
  const firm: Firm | null = useEoValue(FIRM_SELECTOR)

  return new AppService(
    AppInjector.get(IndexDBService),
    firm
  )
}

export default useAppService
